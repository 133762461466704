// modules
import React from 'react'
// components
import ContactForm from '../components/ContactForm/ContactForm'
import SEO from '../components/shared/SEO/SEO'

// definitions
function ContactPage({ location }) {
  const pageRoute = location.pathname
  const pageTitle = 'Contact'

  return (
    <>
      <SEO title={pageTitle} route={pageRoute} />
      <h1>{pageTitle}</h1>
      <ContactForm />
    </>
  )
}

// exports
export default ContactPage
