// modules
import axios from 'axios'
import React, { useState } from 'react'
import Recaptcha from 'react-google-recaptcha'
// styles
import styles from './ContactForm.module.scss'

// definitions
function ContactForm() {
  // state
  const [botField, setBotfield] = useState('')
  const [recaptchaResponse, setRecaptchaResponse] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [rgpdConsent, setRgpdConsent] = useState(false)
  const [formStatusMessage, setFormStatusMessage] = useState('')

  // URL encoding
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  // handlers
  const handleFormSubmit = event => {
    event.preventDefault()

    const form = event.target
    const formData = {
      'bot-field': botField,
      'g-recaptcha-response': recaptchaResponse,
      consent: rgpdConsent,
      firstName: firstName,
      lastName: lastName,
      email: email,
      message: message,
    }

    if (!botField && recaptchaResponse && rgpdConsent) {
      setFormStatusMessage('Sending your message')
      axios({
        method: 'post',
        url: '/',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: encode({ 'form-name': form.getAttribute('name'), ...formData }),
      })
        .then(() => {
          setFormStatusMessage('Your message has been sent')
          setFirstName('')
          setLastName('')
          setEmail('')
          setMessage('')
          setRgpdConsent(false)
        })
        .catch(error => {
          setFormStatusMessage('Something went wrong, please try again later')
          console.log(error)
        })
    } else {
      setFormStatusMessage('Please verify you are not a robot')
    }
  }

  return (
    <form
      className={styles.ContactForm}
      name="contact"
      data-netlify="true"
      data-netlify-recaptcha="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleFormSubmit}
    >
      <input
        name="bot-field"
        value={botField}
        onChange={event => setBotfield(event.target.value)}
        hidden
      />
      <label htmlFor="firstName">First name</label>
      <input
        className={styles.input}
        id="firstName"
        type="text"
        name="firstName"
        value={firstName}
        onChange={event => setFirstName(event.target.value)}
        placeholder="Your first name"
        required
      />
      <label htmlFor="lastName">Last name</label>
      <input
        className={styles.input}
        id="lastName"
        type="text"
        name="lastName"
        value={lastName}
        onChange={event => setLastName(event.target.value)}
        placeholder="Your last name"
        required
      />
      <label htmlFor="email">Email</label>
      <input
        className={styles.input}
        id="email"
        type="email"
        name="email"
        value={email}
        onChange={event => setEmail(event.target.value)}
        placeholder="Your email address"
        required
      />
      <label htmlFor="message">Message</label>
      <textarea
        className={styles.textarea}
        id="message"
        name="message"
        value={message}
        onChange={event => setMessage(event.target.value)}
        placeholder="Your message"
        required
      />
      <div className={styles.checkboxWrapper}>
        <label htmlFor="consent">
          I accept that the personal informations I send through this form are
          collected to be contacted by the artist. More informations about your
          rights can be found on the privacy page.
        </label>
        <input
          className={styles.checkbox}
          id="consent"
          type="checkbox"
          name="consent"
          checked={rgpdConsent}
          onChange={event => setRgpdConsent(event.target.checked)}
          required
        />
      </div>
      <Recaptcha
        className={styles.Recaptcha}
        size="compact"
        sitekey="6LeTcpoUAAAAACiK2TKpNlofBKXoF4hYq5cHqJww"
        onChange={value => setRecaptchaResponse(value)}
      />
      <button className={styles.sendButton} type="submit">
        Send
      </button>
      {formStatusMessage ? <p>{formStatusMessage}</p> : null}
    </form>
  )
}

// exports
export default ContactForm
